import styled from "styled-components";

export const CaptureArea = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;

  > div {
    border-radius: 14px;
    max-width: 510px;
    overflow: hidden;
    max-height: 550px;
  }
`;

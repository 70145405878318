import { useEffect, useState, useRef } from "react";
import * as S from "./styles";

import useApi from "../../../Api";
import FeedBackPage from "../../../pages/FeedBackPage";

import {
  camera,
  faceDetectionAdapter,
  loadFaceDetectorModels,
} from "@biopassid/face-sdk";
import { base64ToBlob } from "../../../utils/auxs/base64ToBlob";
import { captureConfig } from "./captureConfig";
import texts from "../../../_lang";

type Props = {
  setError: (type?: string) => void;
  sendFn: (blob: Blob, userId: string) => void;
};

const Capture = ({ setError, sendFn }: Props) => {
  const captureAreaRef = useRef<HTMLDivElement>(null);

  const Api = useApi({});

  const [isLoading, setIsLoading] = useState(true);

  const runWorkflow = async (userId: string) => {
    await loadFaceDetectorModels();

    try {
      const { takePicture } = camera();

      const faceCapture = await takePicture({
        element: captureAreaRef.current as HTMLElement,
        faceDetectionAdapter: faceDetectionAdapter,
        options: captureConfig,
        i18n: {
          noFacesDetected: texts.other.cameraLabels.no_faces_detected,
          multipleFacesDetected:
            texts.other.cameraLabels.multiple_faces_detected,
          moveFaceLeft: texts.other.cameraLabels.move_face_left,
          moveFaceRight: texts.other.cameraLabels.move_face_right,
          moveFaceUp: texts.other.cameraLabels.move_face_up,
          moveFaceDown: texts.other.cameraLabels.move_face_down,
          moveFaceForward: texts.other.cameraLabels.move_face_forward,
          moveFaceBack: texts.other.cameraLabels.move_face_back,
          keepStill: texts.other.cameraLabels.keep_still,
          moveFaceCloser: texts.other.cameraLabels.move_face_closer,
        },
      });

      const blobCapture = base64ToBlob(faceCapture.base64);
      sendFn(blobCapture, userId);
    } catch (error) {
      setError();
    }
  };

  const loadWorkflow = async () => {
    const creation = await Api.createOnfidoUser();
    const id = creation.id;

    const token = (await Api.getOnfidoSDKToken(id)) as string;

    if (token) {
      const workflowRunId = (await Api.getWorkflowRunId(id)) as string;
      if (workflowRunId) {
        setIsLoading(false);
        runWorkflow(id);
      } else setError();
    } else setError();
  };

  useEffect(() => {
    const timeout = setTimeout(async () => {
      await loadWorkflow();
    });

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return isLoading ? (
    <FeedBackPage isError={false} msgType="leadingCamera" />
  ) : (
    <S.CaptureArea id="capture-area" ref={captureAreaRef} />
  );
};

export default Capture;

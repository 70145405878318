import { Options } from "@biopassid/face-sdk/types";
import texts from "../../../_lang";

export const captureConfig: Options | undefined = {
  width: "100%",
  height: "100%",
  modelsDirectory: "/models",
  fontFamily: "inherit",
  cameraPresets: {
    aspectRatio: 1.777777777777778,
    preferredResolution: 1080,
  },
  mask: {
    type: "face",
    enabled: true,
    backgroundColor: "#000000",
    backgroundOpacity: 0.6,
    frameColor: "#FFFFFF",
    frameThickness: 3,
  },
  faceDetection: {
    enabled: true,
    autoCapture: true,
    timeToCapture: 3000,
    multipleFacesEnabled: false,
    scoreThreshold: 0.5,
    timeToCaptureFeedbackColor: "#00FF00",
  },
  backButton: {
    enabled: true,
    size: "50px",
    backgroundColor: "#FFFFFF",
    padding: "0",
    label: {
      enabled: false,
      content: "",
      fontSize: "16px",
      fontWeight: "400",
      color: "#000000",
    },
    icon: {
      enabled: true,
      source: "",
      color: "#000000",
      size: "30px",
    },
  },
  switchButton: {
    enabled: true,
    size: "50px",
    backgroundColor: "#FFFFFF",
    padding: "0",
    label: {
      enabled: false,
      content: "",
      fontSize: "16px",
      fontWeight: "400",
      color: "#000000",
    },
    icon: {
      enabled: true,
      source: "",
      color: "#000000",
      size: "30px",
    },
  },
  captureButton: {
    enabled: true,
    size: "50px",
    backgroundColor: "#FFFFFF",
    padding: "0",
    label: {
      enabled: false,
      content: "",
      fontSize: "16px",
      fontWeight: "400",
      color: "#000000",
    },
    icon: {
      enabled: true,
      source: "",
      color: "#000000",
      size: "30px",
    },
  },
  cancelButton: {
    enabled: true,
    size: "50px",
    backgroundColor: "#FF0000",
    padding: "0",
    label: {
      enabled: false,
      content: "",
      fontSize: "16px",
      fontWeight: "400",
      color: "#FFFFFF",
    },
    icon: {
      enabled: true,
      source: "",
      color: "#FFFFFF",
      size: "30px",
    },
  },
  confirmButton: {
    enabled: true,
    size: "50px",
    backgroundColor: "#FFFFFF",
    padding: "0",
    label: {
      enabled: false,
      content: "",
      fontSize: "16px",
      fontWeight: "400",
      color: "#000000",
    },
    icon: {
      enabled: true,
      source: "",
      color: "#000000",
      size: "30px",
    },
  },
  legend: {
    title: {
      enabled: true,
      content: texts.other.cameraLabels.face_capture,
      fontSize: "22px",
      fontWeight: "700",
      color: "#FFFFFF",
    },
    subtitle: {
      enabled: true,
      content: texts.other.cameraLabels.fit_face_space,
      fontSize: "16px",
      fontWeight: "400",
      color: "#FFFFFF",
    },
  },
  loading: {
    dotsSize: "6px",
    dotsSpacing: "2px",
    dotsColor: "black",
    backgroundColor: "#FFFFFF",
    size: "50px",
    enabled: false,
  },
  formatImage: "image/jpeg",
};

const start = {
  title: {
    black: "Registration of",
    orange: "Facial biometrics",
  },
  serviceDes: {
    one: "Facial recognition will soon be used to validate tickets at access terminals.",
    two: "Register your security photo! It is confidential and will not be shared.",
    three:
      "By clicking continue, you agree to the secure storage of this data by Skedway for security purposes, in accordance with the",
    connective: "and",
    identification: "",
  },
  links: {
    privacy: "Privacy Policy",
    data: "and Personal Data Protection",
    terms: "Identification Image Terms of use",
  },
};

const finished = {
  title: {
    black: "Photo registered",
    orange: "successfully!",
  },
  resume:
    "Facial recognition is a safe, simple and passive form of validation.",
  instructions:
    "Introduce yourself to the terminals and follow the validation steps. If you encounter any problems, contact a manager or access our support portal.",
};

const other = {
  systemTitle: {
    spanOne: "Facial",
    spanTwo: "recognition",
  },
  buttons: {
    close: "Close",
    later: "Later",
    next: "Next",
    refresh: "Refresh",
    take_another: "Take another picture",
    start: "Continue",
    retry: "Try again",
  },
  cameraLabels: {
    no_faces_detected: "No faces detected",
    multiple_faces_detected: "Multiple faces detected",
    move_face_left: "Move your face to the left",
    move_face_right: "Move your face to the right",
    move_face_up: "Move your face up",
    move_face_down: "Move your face down",
    move_face_forward: "Move your face forward",
    move_face_back: "Move your face back",
    keep_still: "Keep your face still",
    move_face_closer: "Move your face closer",
    face_capture: "Face Capture",
    fit_face_space: "Fit your face on the space above",
  },
};

const feedbacks = {
  leadingCamera: {
    description: "Preparing the camera",
    instructions: "Wait a minute",
  },
  cameraDenied: {
    description: "Camera access denied",
    instructions:
      "Release your camera's access permissions to record your face",
  },
  uploading: {
    description: "Sending biometric data",
    instructions: "",
  },
  unknown: {
    description: "Unknown error",
    instructions: "If the error persists, please contact our support",
  },
  sendError: {
    description: "Unknown error",
    instructions: "If the error persists, please contact our support",
  },
};

const langPattern = "en-US";

const texts = {
  start,
  finished,
  other,
  langPattern,
  feedbacks,
};

export default texts;

const start = {
  title: {
    black: "Registro da",
    orange: "Biometria facial",
  },
  serviceDes: {
    one: "Em breve será utilizado o reconhecimento facial para a validação da passagem nos terminais de acesso.",
    two: "Cadastre a sua foto de segurança! Ela é confidencial e não será compartilhada.",
    three:
      "Ao clicar em continuar, você concorda com o armazenamento seguro deste dado pela Skedway para fins de segurança, conforme a",
    connective: "e",
    identification: "de identificação.",
  },
  links: {
    privacy: "Política de Privacidade",
    data: "Proteção de Dados Pessoais",
    terms: "Termos de Uso de Imagem",
  },
};

const finished = {
  title: {
    black: "Foto cadastrada com",
    orange: "sucesso!",
  },
  resume:
    "O reconhecimento facial é uma forma segura, simples e passiva de validacão.",
  instructions:
    "Apresente-se aos terminais e siga as etapas de validação. Caso encontre algum problama, entre em contato com um gestor ou acesse nosso portal de atendimento.",
};

const other = {
  buttons: {
    close: "Fechar",
    later: "Mais tarde",
    next: "Próximo",
    refresh: "Atualizar",
    take_another: "Tirar outra foto",
    start: "Continuar",
    retry: "Tentar novamente",
  },
  cameraLabels: {
    no_faces_detected: "Nenhum rosto detectado",
    multiple_faces_detected: "Vários rostos detectados",
    move_face_left: "Mova seu rosto para a esquerda",
    move_face_right: "Mova seu rosto para a direita",
    move_face_up: "Mova seu rosto para cima",
    move_face_down: "Mova seu rosto para baixo",
    move_face_forward: "Mova seu rosto para frente",
    move_face_back: "Mova seu rosto para trás",
    keep_still: "Mantenha seu rosto parado",
    move_face_closer: "Mova seu rosto para mais perto",
    face_capture: "Captura de Rosto",
    fit_face_space: "Coloque seu rosto no espaço acima",
  },
};

const feedbacks = {
  leadingCamera: {
    description: "Preparando câmera",
    instructions: "Aguarde um instante",
  },
  cameraDenied: {
    description: "Acesso a câmera negado",
    instructions:
      "Libere as permissões de acesso da sua câmera para registrar sua face",
  },
  uploading: {
    description: "Enviando dados biometricos",
    instructions: "",
  },
  unknown: {
    description: "Erro desconhecido",
    instructions: "Se o erro persistir, por gentileza contate nosso suporte",
  },
  sendError: {
    description: "Erro desconhecido",
    instructions: "Se o erro persistir, por gentileza contate nosso suporte",
  },
};

const langPattern = "pt-BR";

const texts = {
  start,
  finished,
  other,
  langPattern,
  feedbacks,
};

export default texts;

export const base64ToBlob = (
  base64: string,
  contentType: string = ""
): Blob => {
  const base64Prefix = base64.split(",")[0];
  if (
    !contentType &&
    base64Prefix.indexOf("data:") === 0 &&
    base64Prefix.indexOf(";base64") > 0
  ) {
    contentType = base64Prefix.split(":")[1].split(";")[0];
  }

  const base64Data = base64.split(",")[1];

  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], { type: contentType });
};
